define("discourse/plugins/discourse-google-groups-link/templates/connectors/topic-category/google-group-link", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.topic.google_group_link}}
    {{i18n "google_groups_link.link_intro"}}
    {{#if currentUser.external_links_in_new_tab}}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={{this.topic.google_group_link}}
      >
        {{i18n "google_groups_link.link_target"}}
      </a>
    {{else}}
      <a href={{this.topic.google_group_link}}>
        {{i18n "google_groups_link.link_target"}}
      </a>
    {{/if}}
  {{/if}}
  */
  {
    "id": "PVLwX6vF",
    "block": "[[[41,[30,0,[\"topic\",\"google_group_link\"]],[[[1,\"  \"],[1,[28,[35,1],[\"google_groups_link.link_intro\"],null]],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"external_links_in_new_tab\"]],[[[1,\"    \"],[10,3],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[15,6,[30,0,[\"topic\",\"google_group_link\"]]],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"google_groups_link.link_target\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,3],[15,6,[30,0,[\"topic\",\"google_group_link\"]]],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"google_groups_link.link_target\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,[28,[32,0],[\"[[\\\"The `currentUser` property path was used in the `discourse/plugins/discourse-google-groups-link/templates/connectors/topic-category/google-group-link.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentUser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-google-groups-link/templates/connectors/topic-category/google-group-link.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});